.button {
  height: 15rem;
  width: 12rem;
  pointer-events: none;
}

.entry {
  pointer-events: all;
  width: 40rem;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  background-color: rgb(179, 215, 179);
  border: 1px solid rgb(75, 74, 72);
  border-top: none;
  color: rgb(64, 63, 61)
}

.entryTitle {
  align-self: center;
  font-size: 4rem;
  color: rgb(75, 74, 72);
}

.icon {
  width: 6rem;
  height: 6rem;
  align-self: center;
}
