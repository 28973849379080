.header {
    display: flex;
    justify-content: space-between;
    height: 6.2rem;
    background-color: #00513b;
    border-bottom: .2rem solid white;
    user-select: none;
}

.menuToggle {
    height: 100%;
    width: 6rem;
    opacity: 0.5;
    cursor: pointer;
    padding: 1.6rem;
}

.menuToggle svg {
    height: 100%;
}

.menuToggle.toggled {
    opacity: 1;
}

.menuToggle.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

.menuToggle:hover {
    opacity: 1;
}

.logo {
    padding: .8rem;
    padding-right: 1.6rem;
    height: 100%;
}

.logo a {
    height: 100%;
}

.logo img {
    height: 100%;
    width: auto;
}