.mapOverlay {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
  
.gradient {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 12.8rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.gradient span {
  color: white;
  font-size: 2.4rem;
  margin: 2rem;
  white-space: nowrap;
}

.menuTransform {
  position: relative;
  left: 0;
  width: 0;
  min-width: 0;
  height: 100%;
  transition-property: width, min-width;
  transition-duration: .2s;
  z-index: 1;
}

.menuTransform.toggled {
  width: 37.6rem;
  min-width: 37.6rem;
}

.menuContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 37.6rem;
  height: 100%;
  pointer-events: visible;
}