.listEntry {
    cursor: pointer;
}

.listEntry:hover {
    opacity: 0.8;
}

.container {
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconContainer {
    position: relative;
    margin-right: 0.8rem;
    margin-left: 1.4rem;;
    margin-top: 0.8rem;
}

.icon {
    width: 4.4rem;
    height: 4.4rem;
}

.iconText {
    font-family: 'scoutBold';
    position: absolute;
    top: 28%;
    left: 0;
    width: 100%;
    height: 50%;
    color: white;
    text-align: center;
    font-size: 2rem;
}

.info {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 1.6rem;
}

.infoRow1,
.infoRow2 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.infoRow1 {
    font-family: 'scoutBold';
}

.infoTitle {
    max-width: 75%;
    font-size: 2.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.infoLength {
    margin-top: auto;
    margin-left: auto;
    font-size: 1.8rem;
}

.infoMetadata {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.metadataText {
    color: #666666;
    font-size: 1.6rem;
    max-width: 75%;
}

.statusText {
    margin-left: auto;
    color: #666666;
    font-size: 1.6rem;
}