.pathInfoContainerStandAlone {
  position: absolute;
  margin: 0 20px 20px 0;
  border-radius: 16px;
  bottom: 0;
  z-index: 100;
  right: 0;
  background-color: white;
  width: 40%;
  height: 400px;
  max-height: 90vh;
   min-height: 40px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pathInfoContainerStandAlone::-webkit-scrollbar {
  display: none;
}
.pathInfoContainer {
  height: 200px;
  max-height: 80vh;
  min-height: 10vh;
  z-index: 10000;
  overflow: scroll;
  bottom: 0;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 16px;
}

.subPathInfoContainerStandAlone {
  position: absolute;
  margin: 0 20px 20px 0;
  border-radius: 16px;
  bottom: 0;
  z-index: 100;
  right: 0;
  background-color: white;
  width: 40%;
  height: 100px;
  max-height: 90vh;
   min-height: 40px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.subPathInfoContainerStandAlone::-webkit-scrollbar {
  display: none;
}
.subPathInfoContainer {
  height: 100px;
  max-height: 80vh;
  min-height: 10vh;
  z-index: 10000;
  overflow: scroll;
  bottom: 0;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 16px;
}


.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 14px;
  width: 14px;
}

.header {
  display: flex;
  flex-direction: column;
}

.headerInfoSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconText {
  font-family: "scoutBold";
  position: absolute;
  top: 38%;
  left: 0;
  width: 100%;
  height: 50%;
  color: white;
  text-align: center;
  font-size: 2rem;
}

.iconTextMobile {
  font-family: "scoutBold";
  position: absolute;
  top: 38%;
  left: 0;
  width: 100%;
  height: 50%;
  color: white;
  text-align: center;
  font-size: 14px;
}

.title {
  margin-block-end: 0;
}

.titleMobile {
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-size: 18px;
}

.iconContainer {
  position: relative;
  margin-right: 0.8rem;
  margin-left: 1.4rem;
  margin-top: 0.8rem;
  width: 4.4rem;
  height: 4.4rem;
}

.iconContainerMobile {
  position: relative;
  height: 32px;
  width: 32px;
  margin-left: 16px;
  margin-right: 12px;
}

.difficultyIcon {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.6em;
}

.difficultyIconMobile {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.headerRightCol {
  display: flex;
  flex-direction: column;
}

.difficultyRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.difficultyText {
  font-size: 16px;
  color: #666666;
}

.statusRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.statusText {
  font-size: 14px;
}

.length {
  font-weight: 600;
  font-size: 15px;
}

.descriptionContainer {
  padding: 16px;
}

.elevationText {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 16px;
}

.snowmobilePathDescription {
  font-size: 16px;
  margin: 32px 16px 32px 16px;

}
.descriptionText {
  font-size: 16px;
}

.dragHandle {
  width: 30px;
  height: 5px;
  position: absolute;
  left: 0;
  right: 0;
  top: 12px;
  
  background-color: black;
  border-radius: 10px;
  cursor: grab;
  margin: 0 auto;
}

.handleWrapper {
  padding-bottom: 24px;
  width: 100%;
  cursor: grab;
  position: sticky;

}

