.content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    flex: 1;
    background: #ffffff;
    font-size: 3.2rem;
}

.list {
    display: flex;
    flex: 1;
    overflow: hidden;
}