.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.mapContainer {
  position: relative;
  display: flex;
  flex: 1;
}

.buttonContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
}

.categoryMenuContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
}
