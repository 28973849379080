.container {
    border-bottom: .2rem solid white;
    user-select: none;
}

.filters {
    display: flex;
    min-height: 3.3rem;
    font-size: 1.8rem;
    background-color: #67a2c04d;
}

.filter {
    display: flex;
    cursor: pointer;
    margin-left: 1.6rem;
    margin-right: .4rem;
    margin-top: -.3rem;
    color: #4684A5;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    line-height: 0;
}

.filter:first-of-type {
    font-family: 'scoutBold';
}

.filter:not(:first-of-type) {
    margin-left: .8rem;
}

.filterText {
    white-space: nowrap;
}

.arrowIcon {
    margin-left: .2rem;
    width: .8rem;
    height: .8rem;
}

.filter.active .arrowIcon {
    transform: scaleY(-1);
}

.toggled .optionsContainer {
    max-height: 25.6rem;
}

.optionsContainer {
    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: .2s;
    background-color: #67a2c025;
}

.options {
    display: flex;
    flex-wrap: wrap;
    padding: 1.6rem 1.6rem 0 1.6rem;
}

.option:first-of-type {
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.8rem;
    color: #4684A5;
    margin-bottom: 1.6rem;
    width: 50%;
}

.option.active {
    color: black;
}

.optionIcon {
    margin-right: .8rem;
    width: 1.6rem;
    height: 1.6rem;
}