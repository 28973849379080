.listContainer {
    width: 100%;
    overflow: auto;
}

.evenEntry {
    background: #FFFFFF;
}

.oddEntry {
    background: #EEEEEE;
}


.selectedEntry {
    border-left: .8rem solid #00513b;
}

.highlightedEntry {
    border-left: .8rem solid #00513b;
}

.empty {
    margin-left: 1.6rem;
    font-size: 1.8rem;
    color: #666666;
}