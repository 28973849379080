html, body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

html {
  font-size: 1vw;
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html.standalone {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'scoutRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: scoutBold;
  src: local('scoutBold'), url(./assets/fonts/Scout-Bold.otf);
}

@font-face {
  font-family: scoutRegular;
  src: local('scoutRegular'), url(./assets/fonts/Scout-Regular.otf);
}

@font-face {
  font-family: scoutLight;
  src: local('scoutLight'), url(./assets/fonts/Scout-Light.otf);
}