.mapButtons {
    position: absolute;
    bottom: 4rem;
    right: 2rem;
    pointer-events: all;;
}

.buttonRow {
    position: relative;
    margin-top: 2rem;
}

.button {
    height: 12rem;
    width: 12rem;
}

.button.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.menu {
    display: flex;
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    width: calc(100vw - 4rem);
    height: 100%;
    background: white;
    border-radius: 2rem;
    border: 0.25rem solid #bbb;
    transition-property: right;
    transition-duration: .2s;
}

.menu.hidden {
    right: -100vw;
}

.cross {
    height: 8rem;
    width: 8rem;
    margin: auto;
    margin-right: 2rem;;
}

.menu input[type="radio"] {
    display: none;
}

.menu input[type="radio"] + label {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4rem;
    font-family: scoutBold;
    font-size: 6rem;
    text-decoration: none;
    color: #4684a5;
}

.menu input[type="radio"]:checked + label {
    color: black;
}