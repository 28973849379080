.MapView {
    display: flex;
    flex: 1;
}

.ol-rotate,
.ol-attribution {
    display: none;
}

.ol-zoom {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 1.6rem;
    bottom: 1.6rem;
}

.ol-zoom-in,
.ol-zoom-out {
    appearance: none;
    border: none;
    width: 4.4rem;
    height: 4.4rem;
    font-size: 5.2rem;
    font-family: 'scoutRegular';
    line-height: 0;
    color: #67a2c0;
    background-color: white;
    cursor: pointer;
}

.ol-zoom-in {
    border-radius: 2.2rem 2.2rem 0 0;
    margin-bottom: .2rem;
}

.ol-zoom-out {
    border-radius: 0 0 2.2rem 2.2rem;
    padding-bottom: 1.6rem;
}