.dropdown {
  pointer-events: all !important;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 2;
  background: rgba(255,255,255,0.7)
}

.menuContainer {
  pointer-events: all !important;
  width: 40rem;
  height: 10rem;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  border: 2px solid rgb(75, 74, 72);
}
.text {
  margin-left: 2rem;
  font-size: 5rem;
  align-self: center;
  color: rgb(64, 63, 61);
}
.chevron {
  height: 6rem;
  width: 6rem;
  margin: auto;
  margin-right: 2rem;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: rgb(64, 63, 61)
}

.chevronOpen {
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  height: 6rem;
  width: 6rem;
  margin: auto;
  margin-right: 2rem;
}
.dropdownItems {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;

}
